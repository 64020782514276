const BASE_URL = process.env.REACT_APP_BASE_URL;
const MAIN_HOST = process.env.REACT_APP_MAIN_HOST;
const PARENT_MENU = process.env.REACT_APP_PARENT_MENU;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const ENV = process.env.REACT_APP_ENV;
const MY_HEALTHCARE_URL = process.env.REACT_APP_HEALTHCARE_WEB_URL;
const HOMECARE_ACCOUNT = process.env.REACT_APP_HOMECARE_ACCOUNT;
const HOMECARE_MERCHANT_DIAGNOSTICS_CATEGORY_ID = process.env.REACT_APP_HOMECARE_ACCOUNT_DIAGNOSTICS_CATEGORY_ID;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const STRAPI_API_URL = process.env.REACT_APP_STRAPI_API_URL;
const STRAPI_API_KEY = process.env.REACT_APP_STRAPI_API_KEY;
const GTM_ID = process.env.REACT_APP_GTM_ID;
const CARE_CENTER_PERSON_ID = process.env.REACT_APP_CARE_CENTER_PRESON_ID;
const ICE_SERVER_HOST = process.env.REACT_APP_ICE_SERVER_REQ_HOST;
const ICE_SERVER_KEY = process.env.REACT_APP_ICE_SERVER_REQ_API_KEY;
const VIDEO_CONSULTATION_PRODUCT_IDS = process.env.REACT_APP_VIDEO_CONSULTATION_PRODUCT_IDS.split(" ");


const properties = {
    BASE_URL,
    MAIN_HOST,
    PARENT_MENU,
    GOOGLE_MAP_API_KEY,
    STRIPE_KEY,
    ENV,
    MY_HEALTHCARE_URL,
    HOMECARE_ACCOUNT,
    HOMECARE_MERCHANT_DIAGNOSTICS_CATEGORY_ID,
    RECAPTCHA_SITE_KEY,
    AWS_BUCKET,
    STRAPI_API_URL,
    STRAPI_API_KEY,
    ICE_SERVER_HOST,
    ICE_SERVER_KEY,
    GTM_ID,
    CARE_CENTER_PERSON_ID,
    VIDEO_CONSULTATION_PRODUCT_IDS
};

export default properties;
