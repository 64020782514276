import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { WithLoader } from "components";
import { gtmInitializer } from "utils/utils";
import HomeTestingManually from "pages/HomeTesting/HomeTestingManually";
import NotFound from "pages/NotFound/NotFound";
import Products from "pages/Products/Products";
import Modal from "react-modal";
import "./styles/App.scss";

//********* MIDDLEWARES Routes ***************
import { ProtectedRoutesMiddleware, PublicRoutesMiddleware, NotificationMiddleware } from "middlewares";
const EstablishConnectionMiddleware = lazy(() => import("middlewares/EstablishConnectionMiddleware"));
const LayoutMiddleware = lazy(() => import("middlewares/LayoutMiddleware"));
const AuthLayoutMiddleware = lazy(() => import("middlewares/AuthLayoutMiddleware"));
//********* MIDDLEWARES Routes ***************

//********* SHOP Routes ***************
const ProductDetails = lazy(() => import("pages/ProductDetails/ProductDetails"));
const Locations = lazy(() => import("pages/Locations/Locations"));
const Cart = lazy(() => import("pages/Cart/Cart"));
const Checkout = lazy(() => import("pages/Checkout/Checkout"));
const BookAppointment = lazy(() => import("pages/BookAppointment/BookAppointment"));
const BookedScreen = lazy(() => import("pages/BookAppointment/BookedScreen/BookedScreen"));
const ShippingInformations = lazy(() => import("pages/ShippingInformations/ShippingInformations"));
const TravelerScreen = lazy(() => import("pages/Checkout/TravelerScreen/TravelerScreen"));
const Paypal = lazy(() => import("pages/Paypal/Paypal"));
//********* SHOP Routes ***************

//********* CREATE USER Routes ***************
const Login = lazy(() => import("pages/Authentication/Login/Login"));
const CreateProfile = lazy(() => import("pages/Authentication/CreateProfile/CreateProfile"));
const PersonalDetails = lazy(() => import("pages/Authentication/PersonalDetails/PersonalDetails"));
const AddressDetails = lazy(() => import("pages/Authentication/AddressDetails/AddressDetails"));
const PhoneNumber = lazy(() => import("pages/Authentication/PhoneNumber/PhoneNumber"));
const VerifyPhoneNumber = lazy(() => import("pages/Authentication/PhoneNumber/VerifyPhoneNumber/VerifyPhoneNumber"));
//********* CREATE USER Routes ***************

//********* DASHBOARD Routes ***************
const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const MyOrders = lazy(() => import("pages/Dashboard/MyOrders/MyOrders"));
const ViewOrder = lazy(() => import("pages/Dashboard/MyOrders/ViewOrder/ViewOrder"));
const MyUpcomingAppointments = lazy(() => import("pages/Dashboard/MyAppointments/MyUpcomingAppointments"));
const AppointmentHistory = lazy(() => import("pages/Dashboard/MyAppointments/AppointmentHistory/AppointmentHistory"));
const MyHealthProfile = lazy(() => import("pages/Dashboard/MyHealthProfile/MyHealthProfile"));
const TestResultPage = lazy(() => import("pages/Dashboard/MyHealthProfile/TestResultPage/TestResultPage"));
const BiomarkerPage = lazy(() => import("pages/Dashboard/MyHealthProfile/BiomarkerPage/BiomarkerPage"));
const MyConsultationsDetails = lazy(
    () => import("pages/Dashboard/MyHealthProfile/MyConsultations/MyConsultationsDetails/MyConsultationsDetails")
);
const UploadDocuments = lazy(() => import("pages/Dashboard/UploadDocuments/UploadDocuments"));
const MyAccount = lazy(() => import("pages/Dashboard/MyAccount/MyAccount"));
const FamilyAndFriends = lazy(() => import("pages/Dashboard/FamilyAndFriends/FamilyAndFriends"));
//********* DASHBOARD Routes ***************

//********* VIDEO-CONSULTATION routes ***************
const WaitingConsultationsRoom = lazy(
    () => import("pages/Dashboard/VideoConsultation/WaitingConsultationsRoom/WaitingConsultationsRoom")
);
const EntryConsultationScreen = lazy(
    () => import("pages/Dashboard/VideoConsultation/EntryConsultationScreen/EntryConsultationScreen")
);
const VideoConsultationRoom = lazy(
    () => import("pages/Dashboard/VideoConsultation/VideoConsultationRoom/VideoConsultationRoom")
);
const ConsultationFeedback = lazy(
    () => import("pages/Dashboard/VideoConsultation/ConsultationFeedback/ConsultationFeedbackScreen")
);
//********* VIDEO-CONSULTATION routes ***************
const ResetPassComponent = lazy(() => import("pages/Authentication/ResetPassComponent"));
const ForgotPassword = lazy(() => import("pages/Authentication/ForgotPassword/ForgotPassword"));
const SetYourNewPassword = lazy(() => import("pages/Authentication/SetYourNewPassword/SetYourNewPassword"));
const CheckEmailAndVerify = lazy(() => import("pages/Authentication/CheckEmailAndVerify/CheckEmailAndVerify"));
const ForgotPasswordOTP = lazy(() => import("pages/Authentication/ForgotPassword/ForgotPasswordOTP/ForgotPasswordOTP"));
const ConfirmPasswordJoint = lazy(() => import("pages/Authentication/Login/ConfirmPasswordJoint/ConfirmPasswordJoint"));
const AddFamilyAndFriends = lazy(() => import("pages/Authentication/FamilyAndFriends/AddFamilyAndFriends"));
const AccountAllSet = lazy(() => import("pages/Authentication/AccountAllSet/AccountAllSet"));
const SetYourPassword = lazy(() => import("pages/Authentication/SetYourPassword/SetYourPassword"));
const PasswordResetConfirmation = lazy(
    () => import("pages/Authentication/ForgotPassword/PasswordResetConfirmation/PasswordResetConfirmation")
);
const StartSetup = lazy(() => import("pages/Authentication/StartSetup/StartSetup"));
const InstructionV2 = lazy(() => import("pages/InstructionsV2/InstructionV2"));
const HomeTesting = lazy(() => import("pages/HomeTesting/HomeTesting"));
const Delivery = lazy(() => import("pages/HomeTesting/Delivery/Deliver"));

const VideoCall = lazy(() => import("pages/VideoCall/VideoCall"));
const ChatPage = lazy(() => import("pages/ChatPage/ChatPage"));

Modal.setAppElement("body");
declare global {
    interface Window {
        CookieDeclaration: any;
    }
}

function App() {
    gtmInitializer();
    return (
        <Suspense fallback={<WithLoader isLoading={true} />}>
            <Routes>
                <Route path={"web"} element={<EstablishConnectionMiddleware />}>
                    <Route element={<NotificationMiddleware />}>
                        {/* Protected Routes */}
                        <Route element={<ProtectedRoutesMiddleware />}>
                            <Route element={<LayoutMiddleware />}>
                                <Route path={"shipping-informations"} element={<ShippingInformations />} />
                                <Route path={"checkout"} element={<Checkout />} />
                                <Route path={"booked-screen"} element={<BookedScreen />} />
                                <Route path={"dashboard"} element={<Dashboard />} />
                                <Route path={"upload-documents"} element={<UploadDocuments />} />
                                <Route path={"my-health-profile"} element={<MyHealthProfile />} />
                                <Route path={"my-health-profile/test/:id/:itemId"} element={<TestResultPage />} />
                                <Route
                                    path={"my-health-profile/biomarker/:productOrderId/:productOrderItemId/:resultId"}
                                    element={<BiomarkerPage />}
                                />
                                <Route
                                    path={"my-health-profile/consultations/:id/:itemId"}
                                    element={<MyConsultationsDetails />}
                                />
                                <Route path={"my-orders"} element={<MyOrders />} />
                                <Route path={"my-account"} element={<MyAccount />} />
                                <Route path={"family-and-friends"} element={<FamilyAndFriends />} />
                                <Route path={"my-upcomings"} element={<MyUpcomingAppointments />} />
                                <Route path={"appointments-history"} element={<AppointmentHistory />} />
                                <Route path={"my-orders/view-order/:id"} element={<ViewOrder />} />
                                <Route path={"entry-consultation/:orderId/:orderItemId"} element={<EntryConsultationScreen />} />
                                <Route path={"waiting-consultation-room/:orderId/:orderItemId"} element={<WaitingConsultationsRoom />} />
                                <Route path={"video-consultation-room/:orderId/:orderItemId"} element={<VideoConsultationRoom />} />
                                <Route path={"consultation-feedback"} element={<ConsultationFeedback />} />
                                <Route path={"home-testing"} element={<HomeTesting />} />
                                <Route path={"home-testing/manually"} element={<HomeTestingManually />} />
                                <Route path={"home-testing/delivery"} element={<Delivery />} />
                                <Route path={"home-testing/instructions"} element={<InstructionV2 />} />
                                <Route path={"paypal"} element={<Paypal />} />
                                <Route path={"traveler-screen"} element={<TravelerScreen />} />
                                <Route path={"chat"} element={<ChatPage />} />
                            </Route>
                            <Route element={<AuthLayoutMiddleware />}>
                                <Route path={"video-call"} element={<VideoCall />} />
                            </Route>
                        </Route>

                        {/* Publice Routes */}
                        <Route element={<PublicRoutesMiddleware />}>
                            <Route element={<AuthLayoutMiddleware />}>
                                <Route path={"login"} element={<Login />} />
                                <Route path={"forgot-password"} element={<ForgotPassword />} />
                                <Route path={"forgot-password-otp"} element={<ForgotPasswordOTP />} />
                                <Route path={"check-email"} element={<CheckEmailAndVerify />} />
                                <Route path={"account-verification"} element={<ResetPassComponent />} />
                                <Route path={"sign-up"} element={<CreateProfile />} />
                                <Route path={"forget/set-new-password"} element={<SetYourNewPassword />} />
                                <Route path={"verify"} element={<SetYourPassword />} />
                                <Route path={"set-password"} element={<SetYourPassword />} />
                                <Route path={"confirm-password-joint"} element={<ConfirmPasswordJoint />} />
                                <Route path={"personal-details"} element={<PersonalDetails />} />
                                <Route path={"address-details"} element={<AddressDetails />} />
                                <Route path={"phone-number"} element={<PhoneNumber />} />
                                <Route path={"phone-verification"} element={<VerifyPhoneNumber />} />
                                <Route path={"add-family-member"} element={<AddFamilyAndFriends />} />
                                <Route path={"account-all-set"} element={<AccountAllSet />} />
                                <Route path={"password-reset-confirmed"} element={<PasswordResetConfirmation />} />
                                <Route path={"start-setup"} element={<StartSetup />} />
                            </Route>
                            <Route element={<LayoutMiddleware />}>
                                <Route path={"book-appointment"} element={<BookAppointment />} />
                                <Route path={"product/details"} element={<ProductDetails />} />
                                <Route path={"products"} element={<Products />} />
                                <Route path={"productsinline"} element={<Products />} />
                                <Route path={"cart"} element={<Cart />} />
                                <Route path={"locations"} element={<Locations />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path={"*"} element={<NotFound notFound={true} />} />
                </Route>
                <Route path={"/"} element={<NoRoute />} />
            </Routes>
        </Suspense>
    );
}

const NoRoute = () => {
    return <Navigate to={"/web"} />;
};

export default App;
