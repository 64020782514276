import dayjs from "dayjs";
import { GermanyPostCodes } from "./german-post-codes";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import * as enTranslations from "localizations/en.json";
import * as deTranslations from "localizations/de.json";
import { ProductInfoIState } from "models";
import { IOrderAttachment, IProduct, IProductOrder } from "models/Product";
import properties from "properties";
import TagManager from "react-gtm-module";
import lamejs from "lamejs";

export function addMenuChildItems(list, val, splitIndex) {
    const parentTag = val.parents.substring(0, splitIndex);
    const itemFound = list.find((item) => item.parents === parentTag);
    if (itemFound) {
        if (itemFound.hasOwnProperty("childItems")) {
            itemFound.childItems.push({ ...val });
        } else {
            itemFound.childItems = [{ ...val }];
        }
    }
}

export const menuItemsMapped = (menuItems: any[]) => {
    return menuItems
        .sort((a, b) => a.level - b.level)
        .reduce((acc, val) => {
            if (val.level !== 0) {
                if (val.level === 1) {
                    acc.push({ ...val });
                } else if (val.level === 2) {
                    addMenuChildItems(acc, val, 3);
                } else if (val.level === 3) {
                    acc.forEach((mItem) => {
                        if (mItem.childItems) addMenuChildItems(mItem.childItems, val, 5);
                    });
                }
            }
            return acc;
        }, []);
};

export const filteredProductsCategorized = (products: IProduct[]) => {
    return products?.reduce((acc, val) => {
        if (acc[val.type_of_test]) acc[val.type_of_test].push(val);
        else acc[val.type_of_test] = [val];
        return acc;
    }, {});
};

export const totalPrice = (array: any[]) =>
    array?.reduce((acc, val) => {
        acc += val.price;
        return acc;
    }, 0);

export const toObject = (arr: any[]) =>
    arr.reduce(function (acc, cur, i) {
        acc[cur] = cur;
        return acc;
    }, {});

export const numberOfDays = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();
export const daysArray = Array.from(Array(numberOfDays).keys());

export const days = () => {
    let days = [];
    for (let i: number = 1; i <= 31; i++) {
        days.push(i);
    }
    return toObject(days);
};

export const months = [
    { value: 1, label: "month.january" },
    { value: 2, label: "month.february" },
    { value: 3, label: "month.march" },
    { value: 4, label: "month.april" },
    { value: 5, label: "month.may" },
    { value: 6, label: "month.june" },
    { value: 7, label: "month.july" },
    { value: 8, label: "month.august" },
    { value: 9, label: "month.september" },
    { value: 10, label: "month.october" },
    { value: 11, label: "month.november" },
    { value: 12, label: "month.december" }
];

export const weekDays = [
    { value: 1, label: "week_day.monday" },
    { value: 2, label: "week_day.tuesday" },
    { value: 3, label: "week_day.wednesday" },
    { value: 4, label: "week_day.thursday" },
    { value: 5, label: "week_day.friday" },
    { value: 6, label: "week_day.saturday" },
    { value: 0, label: "week_day.sunday" }
];

export const currentDate = () => {
    let today: Date = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = String(today.getFullYear());
    return `${yyyy}-${mm}-${dd}`;
};

export const years = () => {
    return Array.from({ length: 200 }, (_, i) => new Date().getFullYear() - i);
};

export const getSelectOptions = (list: any, addValueSameAsLabel?: boolean) => {
    let keys = Object.keys(list);
    const result = keys.map((item) => {
        return { value: addValueSameAsLabel ? list[item] : item, label: list[item] };
    });
    return result;
};

const replaceLanguageString = (data: string) => {
    switch (data) {
        case "de":
            return "German";
        case "en":
            return "English";
        case "fr":
            return "French";
        case "el":
            return "Greek";
        case "es":
            return "Spanish";
        case "nl":
            return "Dutch";
        case "ru":
            return "Russian";
        case "tr":
            return "Turkish";
        case "zh":
            return "Chinese";
        default:
            return "English";
    }
};

export const iterateLanguageString = (data: any[]) => {
    let remappedData = data.map((element) => {
        return replaceLanguageString(element);
    });
    return remappedData.join(", ");
};

export const weekdayFromNumberToString = (weekDayAsNumber: number): string => {
    if (weekDayAsNumber < 0 || weekDayAsNumber > 6) return "";
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][weekDayAsNumber];
};

export const handleQuantity = (id: any, operation: string, shoppingCart: IProductOrder[], showDiscount: boolean) => {
    return shoppingCart.map((el) => {
        if (el._uid === id) {
            if (operation === "increment") {
                let quantity = el._quantity + 1;
                return {
                    ...el,
                    _quantity: quantity,
                    _subtotal:
                        showDiscount && el.discount_price
                            ? (el.discount_price / 100) * quantity
                            : (el.price / 100) * quantity
                };
            } else {
                if (el._quantity === 1)
                    return {
                        ...el
                    };
                let quantity = el._quantity - 1;
                return {
                    ...el,
                    _quantity: quantity,
                    _subtotal:
                        showDiscount && el.discount_price
                            ? (el.discount_price / 100) * quantity
                            : (el.price / 100) * quantity
                };
            }
        }
        return el;
    });
};

export const reMapUserProfile = (data: any) => {
    let result = {
        address: [],
        phone: null,
        person: null,
        settings: null,
        person_details: null,
        joinedUsers: [],
    };
    data.forEach((el, idx) => {
        if (idx === 0) {
            let storedLang = localStorage.getItem("lang");
            if (el.data.language !== storedLang) {
                localStorage.setItem("lang", el.data.language.toLowerCase());
            }
            result = { ...(el ? el.data : {}) };
        }
        if (idx === 1) {
            result.address = [...(el ? el.data : {})];
        }
        if (idx === 2) {
            result.phone = [...(el ? el.data : {})];
        }
        if (idx === 3) {
            result.person_details = {
                ...(el ? el.data : {})
            };
        }
        if (idx === 4) {
            result.settings = {
                ...(el ? el.data : {})
            };
        }
        if (idx === 5) {
            result.person = {
                ...(el ? el.data : {})
            };
        }
        if (idx === 6) {
            result.joinedUsers = [...(el ? el.data : {})];
        }
    });
    return result;
};

export const filterByProductGroup = (data: IProductOrder[], productGroup: string[]) => {
    return data.filter((el) => productGroup.includes(el.product_group));
};

export const getBiomarkerLevelImage = (level: string) => {
    switch (level) {
        case "Very Low":
            return "biomarker-very-low";
        case "Low":
            return "biomarker-low";
        case "Normal":
        case "Negative":
            return "biomarker-normal";
        case "High":
        case "Very High":
            return "biomarker-high";
        case "Positive":
            return "biomarker-positive";
        default:
            return "biomarker-normal";
    }
};

export const getCurrentOrder = (input_val: any, fiterType?: string) => {
    return input_val.filter((value) => {
        return (
            ((!value.start || new Date(value.start).getTime() + 300000 >= new Date().getTime()) &&
                !value.ask_for_confirmation &&
                (value.attachments
                    ? value.attachments.filter((attachment) => {
                        return attachment.name.toLowerCase().trim().includes("result");
                    }).length > 0
                        ? false
                        : true
                    : true) &&
                !value.online_consultation &&
                value.record_state === "A") ||
            value.record_state === "T"
        );
    });
};

export const getUpcomingData = (combinedData, type: 'dashboardUpcomings' | 'upcomingsAppointment') => {
    const upcomingData = combinedData.filter((item) => {
        // const endOfTheDay = new Date(item.start);
        // endOfTheDay.setHours(23, 59, 59, 999);
        return item.start
        // ? new Date(endOfTheDay).getTime() >= new Date().getTime() : null;
    });
    const homeTestsOrWalkInOpen = combinedData.filter(
        (item) => (item.product_group === "H" || !item.start) && item.item_record_state === "A"
    );
    const homeTestsOrWalkInPending = combinedData.filter(
        (item) => (item.product_group === "H" || !item.start) && item.item_record_state === "T"
    );

    const otherUpcomingAppointments = upcomingData.sort(
        (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    );

    const groupedData = [...homeTestsOrWalkInOpen, ...otherUpcomingAppointments, ...homeTestsOrWalkInPending];

    // filter cancelled orders by record state C
    const filteredCacelledOrders = groupedData?.filter(
        (data: any) => data.item_record_state !== 'C'
    )

    const data = type === 'dashboardUpcomings' ? filterVideoConsultationsOneHoursPassed(filteredCacelledOrders) : filteredCacelledOrders;
    // REMOVE DUPLICATS FORM ARRAY
    const result = data.filter(
        (el, idx, array) =>
            array.findIndex(
                (elv2) =>
                    elv2.product_order_id === el.product_order_id &&
                    elv2.product_order_item_id === el.product_order_item_id
            ) === idx
    );

    return result;
};

const filterVideoConsultationsOneHoursPassed = (data: any) => {
    // Get the current date and subtract one month
    const currentDate = new Date();

    const res = data.filter(item => {
        if (["C"].includes(item.product_group)) {
            const itemDate = new Date(item.start);
            const timeDifference = (currentDate.getTime() - itemDate.getTime()) / (1000 * 60 * 60); // Time difference in hours
            return timeDifference <= 1; // Keep items that are within 1 hour
        }
        return item
    });
    return res
}


export const parseMDY = (s) => {
    let b = (s || "").split(/\D/);
    return new Date(b[2], b[0] - 1, b[1]);
};

export const getClosestAppointment = (orders, product_group) => {
    let now = new Date();
    const timeNow = now.getTime();
    const dateToCheck = product_group === "H" ? "delivery_date" : "start";

    if (!Array.isArray(orders)) orders = [orders];
    if (orders.length === 1) return orders[0];

    return orders
        .sort((a, b) => new Date(a[dateToCheck]).getTime() - new Date(b[dateToCheck]).getTime())
        .reduce(function (acc, item) {
            const itemDate = new Date(item[dateToCheck]).getTime();
            const futureLimit = 300000;

            const smallerCondition =
                itemDate <= timeNow && itemDate + futureLimit >= timeNow && (acc ? itemDate < acc : true);
            const largerCondition = itemDate > timeNow && (acc ? itemDate < acc : true);

            if (smallerCondition || largerCondition) {
                return item;
            } else return acc;
        }, null);
};

// export const getUpcomingAppointments = (orders: IProductOrder[]) => {
//     const upcomingData = orders.filter((item) => {
//         const endOfTheDay = new Date(item.start);
//         endOfTheDay.setHours(23, 59, 59, 999);
//         return item.start ? new Date(endOfTheDay).getTime() >= new Date().getTime() : null;
//     });
//     const homeTestsOrWalkIn = orders.filter(
//         (item) => (item.product_group === "H" || !item.start) && item.record_state === "A"
//     );
//     const otherUpcomingAppointments = upcomingData.sort(
//         (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
//     );
//     const result = [...homeTestsOrWalkIn, ...otherUpcomingAppointments];
//     return result;
// };

export const getAppointmentsHistoryWithTimeslots = (orders: IProductOrder[]) => {
    // let pendingOrClosedRecordStates = ["T", "R"];
    return orders.filter(
        (order) => order.record_state === "R"
        // order.start &&
        // new Date(order.start).getTime() <= new Date().getTime() &&
        // pendingOrClosedRecordStates.includes(order.record_state)
    );
};

export const validateGermanyPostCode = (postcode: string) => {
    if (GermanyPostCodes.has(postcode)) {
        return true;
    } else return false;
};

export const getDefaultDeliveryDate = () => {
    let date = new Date();
    const deliveryDate = date.getTime() + 172800000;
    const addDays = new Date(deliveryDate).toString().includes("Sun") ? 3 : 2;
    date.setDate(date.getDate() + addDays);
    dayjs.extend(utc);
    dayjs.extend(tz);
    let getTodayLatestCheck = new Date();
    getTodayLatestCheck.setHours(15, 0, 0, 0);
    if (dayjs.tz(getTodayLatestCheck, "Europe/Berlin").format() < dayjs.tz(new Date(), "Europe/Berlin").format()) {
        date.setDate(date.getDate());
    }
    return date;
};

export const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${Number(minutes) % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getMinutes} : ${getSeconds} Min`;
};

export const getStatus = (status: string) => {
    switch (status) {
        case "A":
        case "T":
            return "status.open";
        case "R":
            return "status.closed";
        case "C":
            return "status.canceled";
        default:
            break;
    }
};
export const getMyUppcomingsStatus = (status: string) => {
    switch (status) {
        case "A":
            return "status.open";
        case "T":
            return "status.awaitingResult";
        case "R":
            return "status.closed";
        case "C":
            return "status.canceled";
        default:
            break;
    }
};

export const multiProductStatus = (status_record_states) => {
    const currentStatus = status_record_states.find((el) => el === "A" || el === "T")
        ? "status.open"
        : status_record_states.find((el) => el === "C")
            ? "status.canceled"
            : "status.closed";
    return currentStatus;
};

export const filterProductsByGroupId = (data: any, products: any) => {
    let selectTestType = findObjectById(data.menuItems, data.id);

    return products?.filter((item: any) => selectTestType?.product_test_types?.includes(item.type_of_test));
};

export const getPriceWithVoucher = (price: number, voucherAmount: number) => {
    return Math.max(0, price - voucherAmount);
};

export const getGender = (gender: string) => {
    switch (gender) {
        case "M":
            return "gender.male";
        case "F":
            return "gender.female";
        case "X":
            return "gender.unspecified";
        default:
            break;
    }
};

export const birthDateFormatted = (month, day, year) => {
    return dayjs(new Date(month + "/" + day + "/" + year)).format("YYYY-MM-DD");
};

export const getRelationship = (relationship: string) => {
    switch (relationship) {
        case "first_degree_relative":
            return "relationship.first_degree_relative";
        case "custodial_person":
            return "relationship.custodial_person";
        case "legal_representative":
            return "relationship.legal_representative";
        case "deputy":
            return "relationship.deputy";
        case "second_degree_relative":
            return "relationship.second_degree_relative";
        case "third_degree_relative":
            return "relationship.third_degree_relative";
        case "fourth_degree_relative":
            return "relationship.fourth_degree_relative";
        case "dependant_relationship":
            return "relationship.dependant_relationship";
        case "team_member":
            return "relationship.team_member";
        case "other_official_relationship":
            return "relationship.other_official_relationship";
        default:
            break;
    }
};

export const relationshipOptions = [
    { label: "relationship.first_degree_relative", value: "first_degree_relative" },
    { label: "relationship.custodial_person", value: "custodial_person" },
    { label: "relationship.legal_representative", value: "legal_representative" },
    { label: "relationship.deputy", value: "deputy" },
    { label: "relationship.other_official_relationship", value: "other_official_relationship" }
];

export const gendersArray = () => [
    { value: "M", label: "gender.male" },
    { value: "F", label: "gender.female" }
    // { value: "X", label: "gender.unspecified" }
];

export const getIdentNotifications = (data: any) => {
    return data?.filter((item) => item.group === "ident")[0]?.notifications;
};

export const calculateDistance = (lat, lng, locationLatitude, locationLongitude) => {
    return (
        Math.sqrt(Math.pow(parseFloat(locationLatitude) - lat, 2) + Math.pow(parseFloat(locationLongitude) - lng, 2)) *
        110
    ).toFixed(2);
};

export const getJsonValueByKey = (json, key) => JSON.parse(json)[key];

let appLanguage = () => {
    return localStorage.getItem("lang");
};

export const getTranslatedValueByKey = (key: string) => {
    return getJsonValueByKey(JSON.stringify(appLanguage() === "en" ? enTranslations : deTranslations), key);
};

export const openPrivacyPolicy = (translateCurrentLang: string) => {
    switch (translateCurrentLang) {
        case "en": {
            window.open(`${properties.MY_HEALTHCARE_URL}dpn`, "_blank");
            break;
        }
        case "de": {
            window.open(`${properties.MY_HEALTHCARE_URL}dpn`, "_blank");
            break;
        }
        default:
            window.open(`${properties.MY_HEALTHCARE_URL}dpn`, "_blank");
            break;
    }
};

export const openTermsAndCondition = (translateCurrentLang: string) => {
    switch (translateCurrentLang) {
        case "en": {
            window.open(`${properties.MY_HEALTHCARE_URL}tac`, "_blank");
            break;
        }
        case "de": {
            window.open(`${properties.MY_HEALTHCARE_URL}tac`, "_blank");
            break;
        }
        default:
            window.open(`${properties.MY_HEALTHCARE_URL}dpn`, "_blank");
    }
};

export const openDetailsPrivacyOffer = (translateCurrentLang: string) => {
    switch (translateCurrentLang) {
        case "en": {
            window.open(`${properties.MY_HEALTHCARE_URL}dpo`, "_blank");
            break;
        }
        case "de": {
            window.open(`${properties.MY_HEALTHCARE_URL}dpo`, "_blank");
            break;
        }
        default:
            window.open(`${properties.MY_HEALTHCARE_URL}tou`, "_blank");
    }
};

export const openTermsOfRevocation = (translateCurrentLang: string) => {
    switch (translateCurrentLang) {
        case "en": {
            window.open("https://www.ecocare.center/agb#c81245-widerrufsrecht", "_blank");
            break;
        }
        case "de": {
            window.open("https://www.ecocare.center/agb#c81245-widerrufsrecht", "_blank");
            break;
        }
        default:
            window.open("https://www.ecocare.center/agb#c81245-widerrufsrecht", "_blank");
    }
};

export const openTermsOfUse = (translateCurrentLang: string) => {
    switch (translateCurrentLang) {
        case "en": {
            window.open(`${properties.MY_HEALTHCARE_URL}tou`, "_blank");
            break;
        }
        case "de": {
            window.open(`${properties.MY_HEALTHCARE_URL}tou`, "_blank");
            break;
        }
        default:
            window.open(`${properties.MY_HEALTHCARE_URL}tou`, "_blank");
    }
};

export const openCancelPolicy = (translateCurrentLang: string) => {
    switch (translateCurrentLang) {
        case "en": {
            window.open(`${properties.MY_HEALTHCARE_URL}rp`, "_blank");
            break;
        }
        case "de": {
            window.open(`${properties.MY_HEALTHCARE_URL}rp`, "_blank");
            break;
        }
        default:
            window.open(`${properties.MY_HEALTHCARE_URL}rp`, "_blank");
    }
};

export const openDeclarationOfConsent = (translateCurrentLang: string) => {
    switch (translateCurrentLang) {
        case "en": {
            window.open("https://www.ecocare.center/en/consent", "_blank");
            break;
        }
        case "de": {
            window.open("https://www.ecocare.center/einwilligungserklaerungen", "_blank");
            break;
        }
        default:
            window.open("https://www.ecocare.center/en/consent", "_blank");
    }
};

export const openImpressum = (translateCurrentLang: string) => {
    switch (translateCurrentLang) {
        case "en": {
            window.open("https://www.ecocare.center/impressum", "_blank");
            break;
        }
        case "de": {
            window.open("https://www.ecocare.center/impressum", "_blank");
            break;
        }
        default:
            window.open("https://www.ecocare.center/impressum", "_blank");
    }
};

export const getTestResult = (attachments: IOrderAttachment[]) => {
    if (attachments !== undefined) {
        let getfile = attachments.filter((attachment) => {
            return attachment.name.toLowerCase().trim().includes("result");
        });
        if (getfile.length > 0) {
            return getfile[0].link;
        }
    }
    return undefined;
};

export const findObjectById = (array: any[], group_id: number) => {
    for (const obj of array) {
        if (obj.product_category_id.toString() === group_id.toString()) {
            return obj;
        }
        if (obj.childItems) {
            const foundObj = findObjectById(obj.childItems, group_id);
            if (foundObj) {
                return foundObj;
            }
        }
    }
    return undefined;
};

export const getThisProductOnAllLocations = (locations, product_id) => {
    return locations
        .filter((location) => location?.product_list?.find((product) => product.product_id === product_id))
        .sort((a, b) => a.price - b.price);
};

export const getProductListFromAllLocations = (locations) => {
    return locations
        .filter((item) => item.type !== "H")
        .map((e) => e.product_list)
        .filter(Boolean)
        .flat();
};

export const locationsWithCheapestPriceOfProduct = (locations: any[]) => {
    return Object.values(
        getProductListFromAllLocations(locations).reduce((acc, val) => {
            if (acc[val.product_id] && acc[val.product_id].price > val.price) acc[val.product_id] = val;
            else if (!acc[val.product_id]) acc[val.product_id] = val;
            return acc;
        }, {})
    )
        ?.sort((a: any, b: any) => a.product_name - b.product_name)
        .filter((product: any) => product.product_group !== "H");
};

export const defaultProductImagesSlider = () =>
    Array(4).fill({
        original: "https://mytests.ch/wp-content/uploads/2022/03/VitaminD_Person-scaled.jpg",
        thumbnail: "https://mytests.ch/wp-content/uploads/2022/03/VitaminD_Person-scaled.jpg"
    });

export const capitalize = (word: string | undefined) => {
    return word ? word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase() : "";
};

export const getProductInfoByLang = (data: ProductInfoIState[], language: string) => {
    if (data) {
        if (data.length === 1) return data[0]?.text;
        else return data.find((item) => item.language === language).text;
    }
    return "";
};

export const getInitialsFromName = (name: string) =>
    name
        .split(" ")
        .map(function (item) {
            return item[0];
        })
        .join("")
        .toUpperCase();

export const checkProductsUrl = (url: string) => (url.includes("productsinline") ? "productsinline" : "products");

export const biomarkerRangesStyle = (next, biomarker, item, biomarkerRanges, prev) => {
    return {
        width: `${100 / biomarkerRanges?.length}%`,
        backgroundImage: next
            ? `linear-gradient(to right, #${item?.reference_color_code} 70%, #${next && item?.reference_range_name !== "Normal" && next.reference_range_name !== "Normal"
                ? next?.reference_color_code
                : item?.reference_color_code
            })`
            : `linear-gradient(to left, #${item?.reference_color_code} 70%, #${prev && item?.reference_range_name !== "Normal" && prev.reference_range_name !== "Normal"
                ? prev.reference_color_code
                : item?.reference_color_code
            })`,
        backgroundColor: item?.reference_color_code === undefined ? `#${biomarker?.color_code}` : null,
        marginRight: item?.reference_range_name === "Normal" ? 2 : 0,
        marginLeft: item?.reference_range_name === "Normal" ? 2 : 0
    };
};

export const biomarkerRangeInPercentage = (product, biomarker, id) => {
    const findRange = product?.diagnose_reference_ranges?.filter(
        (product) => product.property_id === Number(id) && product.range_color_order === biomarker.range_color_order
    );
    const rangeValues = findRange ? findRange[0].property_range.match(/[\d.]+/g).map(Number) : [];
    const biomarkerValue = parseFloat(biomarker.property_value);
    const percentage = ((biomarkerValue - rangeValues[0]) / (rangeValues[1] - rangeValues[0])) * 100;
    return percentage;
};

declare global {
    interface Window {
        dataLayer: any;
    }
}

export const gtmInitializer = () => {
    if (properties.GTM_ID) {
        TagManager.initialize({
            gtmId: properties.GTM_ID,
            dataLayerName: "dataLayer"
        });
    }
    return;
};

export const WebMToMP3Converter = async (webmBlob) => {
    // Create a new AudioContext instance
    const audioContext = new AudioContext();

    // Decode the WebM blob to an audio buffer
    const arrayBuffer = await webmBlob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Initialize the LAME.js encoder
    const mp3Encoder = new lamejs.Mp3Encoder(audioBuffer.numberOfChannels, audioBuffer.sampleRate, 128);

    // Get left and right channel data
    const leftChannelData = audioBuffer.getChannelData(0);
    const rightChannelData = audioBuffer.numberOfChannels > 1 ? audioBuffer.getChannelData(1) : [];
    // The transformed data, this is what you will pass to lame instead
    // If you are sure to use a Float32Array you can skip this and use [left, right] const.
    const l = new Float32Array(leftChannelData.length);
    const r = new Float32Array(rightChannelData.length);

    //Convert to required format
    for (let i = 0; i < leftChannelData.length; i++) {
        l[i] = leftChannelData[i] * 32767.5;
        r[i] = rightChannelData[i] * 32767.5;
    }

    // Create an MP3 data array
    const mp3Data = [];

    const chunkSize = 1152; // LAME.js processes 1152 samples at a time

    for (var i = 0; i < l.length; i += chunkSize) {
        const leftChunk = l.subarray(i, i + chunkSize);
        const rightChunk = r.subarray(i, i + chunkSize);

        const mp3buf = mp3Encoder.encodeBuffer(leftChunk, rightChunk);

        if (mp3buf.length > 0) {
            mp3Data.push(mp3buf);
        }
    }

    // Flush remaining data
    const mp3Flush = mp3Encoder.flush();
    if (mp3Flush.length > 0) {
        mp3Data.push(new Int8Array(mp3Flush));
    }

    // Concatenate all the MP3 data into a Blob
    const mp3Blob = new Blob(mp3Data, { type: "audio/mp3" });

    // Close the AudioContext
    await audioContext.close();
    return mp3Blob;
};

export const base64ToBlob = (base64String: string, contentType: string) => {
    const base64WithoutPrefix = base64String.split(",")[1];
    const byteCharacters = atob(base64WithoutPrefix);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
};

export function minutesBefore(starting_time) {
    const now = new Date();
    const startTime = new Date(starting_time);
    const timeDifference = startTime.getTime() - now.getTime();
    const minutesBefore = Math.floor(timeDifference / (1000 * 60));

    return minutesBefore;
}