import { produce } from "immer";
import { chat } from "actions";
import ChatIState from "models/Chat";

const initialState: ChatIState = {
    currentConversation: null,
    conversationParticipants: null,
    candidate_offer: [],
    chatConversation: [],
    filteredAllConversations: [],
    allConversations: [],
    inboxChannel: "",
    chatActiveTab: 0,
    iceServerData: null,
    action: {
        isLoading: true,
        error: null,
        isSuccess: false,
        message: "",
        actionName: ""
    }
};
const updateInboxData = (allConversations: any[], msgToUpdate: any) => {
    const res = [...allConversations];
    const fromIndex = res.map((el: any) => el.conversation_id).indexOf(msgToUpdate.conversation_id);
    const element = { ...res.splice(fromIndex, 1)[0] };
    if (element) {
        element.last_payload = msgToUpdate.payload;
        res.splice(0, 0, element);
    }
    return res;
};

const chatReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case chat.UPDATE_MESSAGE_STATUS:
                const result = state.chatConversation.map((el: any) => {
                    if (action.payload.messages_ids.includes(el?.message_external_identifier)) {
                        return {
                            ...el,
                            message_status: [
                                ...el.message_status,
                                { person_id: action.payload.person_id, current_status: action.payload.message_status }
                            ]
                        };
                    }
                    return { ...el };
                });
                draft.chatConversation = result;
                break;
            case chat.CLEAR_CHAT:
                sessionStorage.removeItem("cached_conversation_id");
                draft.chatConversation = [];
                draft.currentConversation = null;
                break;
            case chat.SET_CONVERSATION_ID:
                draft.currentConversation = action.payload;
                break;
            // case chat.SET_SDP_OFFER_OBJECT:
            //     draft.sdp_offer = action.payload;
            //     break;
            case chat.SET_CANDIDATE_OFFER_OBJECT:
                draft.candidate_offer = [...state.candidate_offer, action.payload];
                localStorage.setItem("ice_candidate_offer", JSON.stringify(draft.candidate_offer));
                break;
            case chat.UPDATE_CHAT_CONVERSATION:
                const updatedData = updateInboxData(state.allConversations, action.payload);
                draft.chatConversation = [...draft.chatConversation, action.payload];
                draft.allConversations = updatedData;
                draft.filteredAllConversations = state.inboxChannel !== "" ?
                    updatedData.filter((el: any) => el.conversation_type_name === state.inboxChannel) :
                    updatedData;
                break;
            case chat.SET_CONVERSATION_PARTICIPANTS:
                draft.conversationParticipants = action.payload;
                break;
            case chat.GET_CONVERSATION_HISTORY_BEGIN:
            case chat.GET_ALL_CONVERSATIONS_BEGIN:
            case chat.FETCH_ICE_SERVER_BEGIN:
            case chat.CREATE_CUSTOMER_BEGIN:
                // case chat.GET_PARTICIPANTS_BY_CONVERSATION_BEGIN:
                draft.action.isLoading = true;
                draft.action.isSuccess = false;
                draft.action.error = null;
                draft.action.actionName = action.type;
                break;
            case chat.GET_CONVERSATION_HISTORY_FAILED:
            case chat.GET_ALL_CONVERSATIONS_FAILED:
            case chat.FETCH_ICE_SERVER_FAILED:
            case chat.CREATE_CUSTOMER_FAILED:
                // case chat.GET_PARTICIPANTS_BY_CONVERSATION_FAILED:
                draft.action.isLoading = false;
                draft.action.isSuccess = false;
                draft.action.error = action.payload || "Something went wrong!";
                draft.action.actionName = action.type;
                break;
            case chat.GET_PARTICIPANTS_BY_CONVERSATION_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                // draft.action.actionName = action.type;
                draft.conversationParticipants = action.payload;
                break;
            case chat.GET_CONVERSATION_HISTORY_SUCCESS:
                draft.chatConversation = action.payload.data;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.error = null;
                draft.action.actionName = action.type;
                break;
            case chat.GET_ALL_CONVERSATIONS_SUCCESS:
                let closedConversations = action.payload.data.filter((el: any) => el.conversation_status === "C");
                let otherConversations = action.payload.data.filter((el: any) => el.conversation_status !== "C");
                let getFirstConversation = (allConversations: Array<any>) => {
                    const result = null;
                    if (allConversations.length > 0) return allConversations[0];
                    return result;
                };

                draft.allConversations = [...otherConversations, ...closedConversations];
                draft.filteredAllConversations = [...otherConversations, ...closedConversations]
                draft.currentConversation = action.payload.getFirstConversation
                    ? getFirstConversation([...otherConversations, ...closedConversations])
                    : state.currentConversation;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.error = null;
                draft.action.actionName = action.type;
                break;
            case chat.CREATE_CUSTOMER_SUCCESS:
                draft.inboxChannel = "support"
                draft.chatActiveTab = 1;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.error = null;
                draft.action.actionName = action.type;
                break;
            case chat.UPDATE_CONVERSATION_STATUS:
                draft.currentConversation.conversation_status = "C";
                break;
            case chat.UPDATE_INBOX:
                draft.allConversations = updateInboxData(draft.allConversations, action.payload);
                break;
            case chat.UPDATE_MESSAGE_PAYLOAD:
                const modifiedChatConversations = state.chatConversation.map((obj) => {
                    if (obj.message_external_identifier === action.payload.message_external_identifier) {
                        return { ...obj, message_offer_payload: null };
                    }
                    return obj;
                });
                // draft.chatConversation = modifiedChatConversations;
                draft.chatConversation = [...modifiedChatConversations, action.payload];
                break;
            case chat.FETCH_ICE_SERVER_SUCCESS:
                draft.iceServerData = action.payload;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.error = null;
                draft.action.actionName = action.type;
                break;
            case chat.FILTER_CONVERSATIONS_BY_TYPE:
                const filteredData = action.payload
                    ? state.allConversations.filter((el: any) => el.conversation_type_name === action.payload)
                    : state.allConversations;
                draft.filteredAllConversations = filteredData;
                // draft.currentConversation = filteredData[0];
                draft.currentConversation = null;
                break;
            case chat.SET_INBOX_CHANNEL:
                draft.inboxChannel = action.payload;
                break;
            case chat.SET_CHAT_ACTIVE_TAB:
                draft.chatActiveTab = action.payload;
                break;
            default:
                break;
        }
    });
export default chatReducer;
